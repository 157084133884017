header {
    background-color: #5956E9;
    padding-top: 30px;
    padding-bottom: 30px;

    .full-header {
        max-width: 1440px;
        margin-left: auto;
        margin-right: auto;
    }

    .desktop-header-content {
        background-color: white;
        border-radius: 5px;
        padding-left: 30px;
        padding-right: 30px;

        a {
            color: #232233;
            font-size: 18px;
            font-weight: 550;

            &.logo {
                font-size: 50px;
                color: black;
                font-weight: 850;
                transition: all .3s;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            &.normal-link {
                transition: all .3s;

                &:hover {
                    color: #5956E9;
                }
            }

            &.download-link {
                background-color: #5956E9;
                color: white;
                padding: 6px 32px;
                border-radius: 5px;
                transition: all .3s;

                &:hover {
                    text-decoration: none;
                    background-color: #3a38aa;
                }
            }

            &.active-link {
                color: #5956E9;
                text-decoration: underline;
                transition: all .3s;

                &:hover {
                    color: black;
                }
            }
        }
    }
    
    .mobile-header-content {
        background-color: white;
        border-radius: 5px;
        padding-left: 10px;
        padding-right: 10px;
        a {
            color: #232233;
            font-size: 16px;
            font-weight: 550;

            &.logo {
                font-size: 30px;
                color: black;
                font-weight: 850;
                transition: all .3s;
                margin-right: 5px;

                &:hover {
                    text-decoration: none;
                    cursor: pointer;
                }
            }

            &.normal-link {
                transition: all .3s;

                &:hover {
                    color: #5956E9;
                }
            }

            &.download-link {
                background-color: #5956E9;
                color: white;
                padding: 6px 32px;
                border-radius: 5px;
                transition: all .3s;

                &:hover {
                    text-decoration: none;
                    background-color: #3a38aa;
                }
            }
        }
    }
}


@media screen and (max-width: 768px) {
    .desktop-header-content {
        display: none !important;
    }
    .mobile-header-content {
        display: flex !important;
    }
}

@media screen and (min-width: 769px) {
    .mobile-header-content {
        display: none !important;
    }
}