.cat-image {
    width: 100%;
    padding: 0;
}

.info-box {
    position: relative;
    top: -50px;
    background-color: white;
    border-radius: 24px;
    h3 {
        color: #5956E9;
        padding-top: 26px;
        margin-bottom: 0;
    }

    .cins {
        margin-top: 0;
        font-size: 16px;
        color: #9D9D9D;
    }

    .pet-info {
        .box {
            padding: 10px;
            max-width: 32%;
            min-width: 29%;
            min-height: 150px;
            font-weight: 700;
            font-size: 15px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            span {
                font-weight: 400;
            }

            border-radius: 8px;

            &.bg-box-1 {
                background-color: #F77B7B;
                color: white;
            }

            &.bg-box-2 {
                background-color: #98A7F0;
                color: white;
            }

            &.bg-box-3 {
                background-color: #F6D682;
                color: white;
            }
        }
    }

    .owner-info {
        margin-top: 32px;

        .owner-icon {
            text-align: right;
        }

        .owner {
            text-align: left;

            p {
                margin: 0;

                &.name {
                    color: #5956E9;
                    font-size: 20px;
                    font-weight: 700;
                }

                &.tel {
                    font-size: 16px;
                    color: #9D9D9D;
                }
            }
        }

        .call-owner {
            background-color: #5956E9;
            padding: 3px 10px;
            text-align: center;
            border-radius: 6px;

            a {
                color: white;
            }
        }

        .wp-owner {
            background-color: #1CC923;
            padding: 3px 10px;
            text-align: center;
            border-radius: 6px;

            a {
                color: white;
            }
        }
    }

    .vet-info {
        margin: 0;

        .vet-name {
            color: #5956E9;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 0;
        }

        .vet-tel {
            font-size: 16px;
            color: #9D9D9D;
        }
    }

    .ekstra-bilgi {
        font-size: 16px;
        color: #656565;
    }
}

.footer-text {
    text-align: center;
    font-style: italic;
    font-weight: 700;
    font-size: 14px;
    color: red;
}